var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-col',[_c('validation-observer',{ref:"professionalDataForm"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_c('b-card',{staticClass:"p-2"},[_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label-cols-sm":"3","label-cols-lg":"2","content-cols-sm":"","content-cols-lg":"6","label":"Grado de instrucción","label-for":"studyLevel"}},[_c('validation-provider',{attrs:{"name":"Tipo de documento","vid":"studyLevel"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"studyLevel","options":_vm.optionsStudyLevel,"state":errors.length > 0 ? false:null,"name":"studyLevel","disabled":!_vm.canEdit},model:{value:(_vm.studyLevel),callback:function ($$v) {_vm.studyLevel=$$v},expression:"studyLevel"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label-cols-sm":"3","label-cols-lg":"2","content-cols-sm":"","content-cols-lg":"2","label":"RUC","label-for":"ruc"}},[_c('validation-provider',{attrs:{"name":"RUC","rules":"required","vid":"ruc"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"ruc","state":errors.length > 0 ? false:null,"formatter":_vm.formatterNumber,"name":"ruc","disabled":"","readonly":!_vm.canEdit},model:{value:(_vm.ruc),callback:function ($$v) {_vm.ruc=$$v},expression:"ruc"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label-cols-sm":"3","label-cols-lg":"2","content-cols-sm":"","content-cols-lg":"6","label":"Profesión/Oficio","label-for":"profession"}},[_c('validation-provider',{attrs:{"name":"Profesión","rules":"required","vid":"profession"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"profession","label":"profesion","options":_vm.optionsProfession,"name":"profession","required":"","disabled":!_vm.canEdit},model:{value:(_vm.profession),callback:function ($$v) {_vm.profession=$$v},expression:"profession"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" Lo sentimos, no hay opciones que coincidan. ")])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',[_c('validation-provider',{attrs:{"name":"CIIU","vid":"ciiu"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-cols-sm":"3","label-cols-lg":"2","content-cols-sm":"","content-cols-lg":"6","label":"CIIU","label-for":"ciiu"}},[_c('v-select',{attrs:{"id":"ciiu","options":_vm.optionsCIIU,"name":"ciiu","disabled":!_vm.canEdit},model:{value:(_vm.ciiu),callback:function ($$v) {_vm.ciiu=$$v},expression:"ciiu"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" Lo sentimos, no hay opciones que coincidan. ")])])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label-cols-sm":"3","label-cols-lg":"2","content-cols-sm":"","content-cols-lg":"6","label":"Actividad Principal","label-for":"mainActivity"}},[_c('validation-provider',{attrs:{"name":"Actividad principal","rules":"required","vid":"mainActivity"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"mainActivity","state":errors.length > 0 ? false:null,"name":"mainActivity","readonly":!_vm.canEdit},model:{value:(_vm.mainActivity),callback:function ($$v) {_vm.mainActivity=$$v},expression:"mainActivity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label-cols-sm":"3","label-cols-lg":"2","content-cols-sm":"","content-cols-lg":"6","label":"Ocupación","label-for":"job"}},[_c('validation-provider',{attrs:{"name":"ocupación","rules":"required","vid":"job"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"job","label":"ocupacion","options":_vm.optionsActivities,"name":"job","required":"","disabled":!_vm.canEdit},model:{value:(_vm.job),callback:function ($$v) {_vm.job=$$v},expression:"job"}},[_c('span',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" Lo sentimos, no hay opciones que coincidan. ")])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.job && (_vm.job.compuestoID=='000004'|| _vm.job.compuestoID=='000003')),expression:"job && (job.compuestoID=='000004'|| job.compuestoID=='000003')"}]},[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label-cols":"12","content-cols":"12","label":"Centro laboral/Razón social del negocio*","label-for":"workplace"}},[_c('validation-provider',{attrs:{"name":"Centro laboral/Razón social del negocio","vid":"workplace"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"workplace","state":errors.length > 0 ? false:null,"name":"workplace","readonly":!_vm.canEdit},model:{value:(_vm.workplace),callback:function ($$v) {_vm.workplace=$$v},expression:"workplace"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label-cols":"12","content-cols":"12","label":"RUC*","label-for":"wpRuc"}},[_c('validation-provider',{attrs:{"name":"RUC","vid":"wpRuc"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"wpRuc","state":errors.length > 0 ? false:null,"name":"wpRuc","readonly":!_vm.canEdit},model:{value:(_vm.wpRuc),callback:function ($$v) {_vm.wpRuc=$$v},expression:"wpRuc"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label-cols":"12","content-cols":"12","label":"Fecha de inicio laboral/negocio*","label-for":"wpStartdate"}},[_c('validation-provider',{attrs:{"name":"Fecha de inicio laboral/negocio","vid":"wpStartdate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{attrs:{"id":"wpStartdate","placeholder":"dd/mm/aaaa","name":"wpStartdate","state":errors.length > 0 ? false:null,"config":Object.assign({}, _vm.configDatePickr),"disabled":!_vm.canEdit},model:{value:(_vm.wpStartdate),callback:function ($$v) {_vm.wpStartdate=$$v},expression:"wpStartdate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label-cols":"12","content-cols":"12","label":"Cargo*","label-for":"wpPosition"}},[_c('validation-provider',{attrs:{"name":"Cargo","vid":"wpPosition"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"wpPosition","state":errors.length > 0 ? false:null,"name":"wpPosition","readonly":!_vm.canEdit},model:{value:(_vm.wpPosition),callback:function ($$v) {_vm.wpPosition=$$v},expression:"wpPosition"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label-cols":"12","content-cols":"12","label":"Teléfono*","label-for":"wpPhone"}},[_c('validation-provider',{attrs:{"name":"Teléfono","rules":"max:15","vid":"wpPhone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"wpPhone","state":errors.length > 0 ? false:null,"formatter":_vm.formatterTel,"name":"wpPhone","readonly":!_vm.canEdit},model:{value:(_vm.wpPhone),callback:function ($$v) {_vm.wpPhone=$$v},expression:"wpPhone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label-cols":"12","content-cols":"12","label":"Rubro económico*","label-for":"wpEconomic"}},[_c('validation-provider',{attrs:{"name":"Rubro económico","vid":"wpEconomic"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"wpEconomic","state":errors.length > 0 ? false:null,"name":"wpEconomic","readonly":!_vm.canEdit},model:{value:(_vm.wpEconomic),callback:function ($$v) {_vm.wpEconomic=$$v},expression:"wpEconomic"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label-cols":"12","content-cols":"12","label":"Correo electrónico*","label-for":"wpEmail"}},[_c('validation-provider',{attrs:{"name":"Correo electrónico","rules":"email","vid":"wpEmail"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"wpEmail","state":errors.length > 0 ? false:null,"name":"wpEmail","readonly":!_vm.canEdit},model:{value:(_vm.wpEmail),callback:function ($$v) {_vm.wpEmail=$$v},expression:"wpEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{attrs:{"label-cols":"12","content-cols":"12","label":"Dirección*","label-for":"wpAddress"}},[_c('validation-provider',{attrs:{"name":"Dirección","vid":"wpAddress"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"wpAddress","state":errors.length > 0 ? false:null,"name":"wpAddress","readonly":!_vm.canEdit},model:{value:(_vm.wpAddress),callback:function ($$v) {_vm.wpAddress=$$v},expression:"wpAddress"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{attrs:{"label-cols":"12","content-cols":"12","label":"Urbanización","label-for":"wpStreet"}},[_c('validation-provider',{attrs:{"name":"Urbanización","vid":"wpStreet"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"wpStreet","state":errors.length > 0 ? false:null,"name":"wpStreet","readonly":!_vm.canEdit},model:{value:(_vm.wpStreet),callback:function ($$v) {_vm.wpStreet=$$v},expression:"wpStreet"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"1"}},[_c('b-form-group',{attrs:{"label-cols":"12","content-cols":"12","label":"N°","label-for":"wpStreetNumber"}},[_c('validation-provider',{attrs:{"name":"N°","vid":"wpStreetNumber"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"wpStreetNumber","state":errors.length > 0 ? false:null,"formatter":_vm.formatterNumber,"name":"wpStreetNumber","readonly":!_vm.canEdit},model:{value:(_vm.wpStreetNumber),callback:function ($$v) {_vm.wpStreetNumber=$$v},expression:"wpStreetNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"1"}},[_c('b-form-group',{attrs:{"label-cols":"12","content-cols":"12","label":"Manzana","label-for":"wpBlock"}},[_c('validation-provider',{attrs:{"name":"Manzana","vid":"wpBlock"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"wpBlock","state":errors.length > 0 ? false:null,"name":"wpBlock","readonly":!_vm.canEdit},model:{value:(_vm.wpBlock),callback:function ($$v) {_vm.wpBlock=$$v},expression:"wpBlock"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"1"}},[_c('b-form-group',{attrs:{"label-cols":"12","content-cols":"12","label":"Lote","label-for":"wpLot"}},[_c('validation-provider',{attrs:{"name":"Lote","vid":"wpLot"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"wpLot","state":errors.length > 0 ? false:null,"formatter":_vm.formatterNumber,"name":"wpLot","readonly":!_vm.canEdit},model:{value:(_vm.wpLot),callback:function ($$v) {_vm.wpLot=$$v},expression:"wpLot"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"1"}},[_c('b-form-group',{attrs:{"label-cols":"12","content-cols":"12","label":"N° Dep","label-for":"wpDepNumber"}},[_c('validation-provider',{attrs:{"name":"N° Dep","vid":"wpDepNumber"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"wpDepNumber","state":errors.length > 0 ? false:null,"name":"wpDepNumber","readonly":!_vm.canEdit},model:{value:(_vm.wpDepNumber),callback:function ($$v) {_vm.wpDepNumber=$$v},expression:"wpDepNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"1"}},[_c('b-form-group',{attrs:{"label-cols":"12","content-cols":"12","label":"Interior","label-for":"wpInside"}},[_c('validation-provider',{attrs:{"name":"Interior","vid":"wpInside"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"wpInside","state":errors.length > 0 ? false:null,"formatter":_vm.formatterNumber,"name":"wpInside","readonly":!_vm.canEdit},model:{value:(_vm.wpInside),callback:function ($$v) {_vm.wpInside=$$v},expression:"wpInside"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{attrs:{"label-cols":"12","content-cols":"12","label":"Distrito*","label-for":"wpDistrict"}},[_c('validation-provider',{attrs:{"name":"Distrito","vid":"wpDistrict"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"wpDistrict","state":errors.length > 0 ? false:null,"name":"wpDistrict","readonly":!_vm.canEdit},model:{value:(_vm.wpDistrict),callback:function ($$v) {_vm.wpDistrict=$$v},expression:"wpDistrict"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"3"}},[_c('b-form-group',{attrs:{"label-cols":"12","content-cols":"12","label":"Provincia*","label-for":"wpProvince"}},[_c('validation-provider',{attrs:{"name":"Provincia","vid":"wpProvince"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"wpProvince","state":errors.length > 0 ? false:null,"name":"wpProvince","readonly":!_vm.canEdit},model:{value:(_vm.wpProvince),callback:function ($$v) {_vm.wpProvince=$$v},expression:"wpProvince"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"2"}},[_c('b-form-group',{attrs:{"label-cols":"12","content-cols":"12","label":"Departamento*","label-for":"wpDepartment"}},[_c('validation-provider',{attrs:{"name":"Departamento","vid":"wpDepartment"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"wpDepartment","state":errors.length > 0 ? false:null,"name":"wpDepartment","readonly":!_vm.canEdit},model:{value:(_vm.wpDepartment),callback:function ($$v) {_vm.wpDepartment=$$v},expression:"wpDepartment"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"2"}},[_c('b-form-group',{attrs:{"label-cols":"12","content-cols":"12","label":"País*","label-for":"wpCountry"}},[_c('validation-provider',{attrs:{"name":"País","vid":"wpCountry"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"wpCountry","state":errors.length > 0 ? false:null,"name":"wpCountry","readonly":!_vm.canEdit},model:{value:(_vm.wpCountry),callback:function ($$v) {_vm.wpCountry=$$v},expression:"wpCountry"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"2"}},[_c('b-form-group',{attrs:{"label-cols":"12","content-cols":"12","label":"Antigüedad*","label-for":"wpOld"}},[_c('validation-provider',{attrs:{"name":"Antigüedad","vid":"wpOld"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"wpOld","state":errors.length > 0 ? false:null,"name":"wpOld","readonly":!_vm.canEdit},model:{value:(_vm.wpOld),callback:function ($$v) {_vm.wpOld=$$v},expression:"wpOld"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.job && _vm.job.compuestoID=='000003'),expression:"job && job.compuestoID=='000003'"}]},[_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label-cols":"12","content-cols":"12","label":"Ingreso Mensual","label-for":"monthlySalary"}},[_c('validation-provider',{attrs:{"name":"Ingreso Mensual","vid":"monthlySalary"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio-group',{attrs:{"id":"monthlySalary","options":_vm.optionsRevenue,"value-field":"value","text-field":"text","state":errors.length > 0 ? false:null,"name":"monthlySalary","disabled":!_vm.canEdit},model:{value:(_vm.monthlySalary),callback:function ($$v) {_vm.monthlySalary=$$v},expression:"monthlySalary"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.job && _vm.job.compuestoID=='000004'),expression:"job && job.compuestoID=='000004'"}]},[_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label-cols":"12","content-cols":"12","label":"Venta bruta mensual","label-for":"monthlyRevenue"}},[_c('validation-provider',{attrs:{"name":"Venta bruta mensual","vid":"monthlyRevenue"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio-group',{attrs:{"id":"monthlyRevenue","options":_vm.optionsRevenue,"value-field":"value","text-field":"text","state":errors.length > 0 ? false:null,"name":"monthlyRevenue","disabled":!_vm.canEdit},model:{value:(_vm.monthlyRevenue),callback:function ($$v) {_vm.monthlyRevenue=$$v},expression:"monthlyRevenue"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-row',{staticClass:"justify-content-center"},[_c('b-col',{attrs:{"sm":"6","md":"3"}},[_c('b-button',{staticClass:"my-1",attrs:{"variant":"secondary","to":{name:'Contact-data'},"block":""}},[_vm._v(" Volver ")])],1),_c('b-col',{attrs:{"sm":"6","md":"3"}},[_c('b-button',{staticClass:"my-1",attrs:{"variant":"primary","block":"","type":"submit"}},[_vm._v(" "+_vm._s(_vm.canEdit ? "Guardar y avanzar" : "Siguiente")+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }